import styled from 'styled-components';

export const Wrapper = styled.div`
    background: linear-gradient(
        to bottom, 
        rgba(0,0,0,0) 41%, 
        rgba(0,0,0,0.65) 100%
        );
    background-color: #3C3E41;
    min-height: 400px;
    padding-top: 50px;
    height: 100%;

    @media screen and (max-width: 768px) {
        min-height:300px;
        padding-top:0;
    };

    h1 {
        color: white;
        font-family: Garamond;
        font-size: 2.5rem;
        font-weight: lighter;

        @media screen and (max-width: 768px) {
            font-size: var(--fontBig);
            height: 50px;
            padding-top: 20px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-left: 300px;
    padding-right:300px;
    padding-top:40px;

    @media screen and (max-width: 768px) {
         padding-left: 50px;
         padding-right: 50px;
         font-size: var(--fontMed)
        }

    .words {
        font-size: 1.5rem;
        text-align: center;
        color: white;
        margin-top:30px;

        @media screen and (max-width: 768px) {
         font-size: 0.7rem
        }
    };

    .click {
        font-size: 1.1rem;
        text-align: center;
        color: white;
        padding-top: 50px;
        transition: all 0.3s ease-in;
        padding-bottom:50px;

        a {
            text-decoration: underline;
            color: #1E87FF;
            cursor: pointer;
            &:hover {
        color: #ffe600;
        }};

        @media screen and (max-width: 768px) {
            padding-top: 20px;
            font-size: 0.6rem;
        }
    
    };
`;

