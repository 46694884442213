import React from 'react';

// Components
import HeroImage from './HeroImage';
import UrFund from './News';
import image5 from '../images/FL_FINOPITCH2024.jpeg'

const AboutUs = () => (
    <>
        
        <HeroImage href="https://4f-otmcbldg.tokyo/finopitch2024-award-winner/" Image={image5} />
        <UrFund />
       

    </>

);

export default AboutUs;
