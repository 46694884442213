import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #222222;
    padding: 10px 40px;
    bottom: 0;
    left: 0;
    right: 0;
    position: relative;

    @media screen and (max-width: 768px) {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: relative;
    align-items: center;
    
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    margin: 0 auto; 
    justify-content: space-between;


`;

export const Menu = styled.div`
    position: relative;
    display:flex;
    justify-content: center;

    @media (max-width: 768px) {
        overflow: hidden;
        width: 100%;
        transition: max-height 0.3s ease-in;
    }
`;

export const MenuLink = styled.a`
    text-align: center;
    text-decoration: none;

    p{
    color: rgb(153, 153, 153)
    };
    a{
    color: #f8a61f;
    }

`;

