import React from 'react';

// Styles
import { Wrapper, Content } from './Contact.styles';
import {Email} from '@styled-icons/material-outlined/Email';


const ContactUs = () => (
    <Wrapper>
        <Content>
            <div className='headings'>
                <h1>Ready to become a client?</h1>
            </div>
            <div className='words'>
               <hr/>
                
            </div>
            <div  className='click' id='contact-section'>
            <Email size="18"/> Email Us: 
            <p>
                <a href="mailto:info@200-west.com">info@200-west.com</a>
                </p>
            </div>
        </Content>
    </Wrapper>
);

export default ContactUs;