import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidth: 1280px;
        --white: #fff;
        --lightGrey: #eee;
        --medGrey: #353535;
        --darkGrey: #1c1c1c;
        --fontSuperBig: 2.5rem;
        --fontBig: 1.5rem;
        --fontMed: 1.1rem;
        --fontSmall: 0.9rem;
    }

    *{
        box-sizing: border-box;
        font-family: 'Arial', sans-serif;

    }

    body {
        margin:0;
        padding: 0;

        h1 {
            font-size: 2rem;
            font-weight: 600;
            color: var(--white)

        }

        h3 {
            font-size: 1.1rem;
            font-weight: 600;

        }

        p {
            font-size: 1.1rem;
            color: #5B5B5B;
            font-weight: lighter;
    
            @media screen and (max-width: 768px) {
            font-size: 0.8rem;
            font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;
        }
        }
    }
`
