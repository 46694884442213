import styled from 'styled-components';

export const Wrapper = styled.div`
    background: white;
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px 0;
    margin: 0 auto;    

    .titles {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .title1{
        padding-left: 15px;
        @media screen and (max-width: 768px) {
            padding-left: 5px;
        }

    h1 {
        font-family: Garamond;
        font-size: 1.5rem;
        font-weight: lighter;
        text-align: center;

        @media screen and (max-width: 768px) {
            font-size: 0.8rem;
            padding-left: 5px;
    }}
    hr {
        border-color: rgb(255, 230, 0);
    }

    p {
        font-family: Garamond;
        font-size: 0.9rem;
        font-weight: lighter;
        color: white;

        @media screen and (max-width: 768px) {
            font-size: 0.5rem;
            padding-left: 5px;
    }}
}
`;

export const LogoImg = styled.img`
    width: 70px;

    @media screen and (max-width: 768px) {
        width: 45px;
}
`;

export const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;

    span{
        height: 4px;
        width: 25px;
        background: #f8a61f;
        margin-bottom: 6px;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        display: flex;
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
        transition: max-height 0.3s ease-in;
    }
`;

export const MenuLink = styled.a`
    padding: 1rem 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: 	#696969;
    transition: all 0.3s ease-in;
    font-size: 1.1rem;
    
    &:hover {
        color: #f8a61f;
    }

    @media screen and (max-width: 768px) {
            font-size: 0.7rem;
    }
`;

