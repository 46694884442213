import React from 'react';

// Styles
import { Wrapper, Content, MenuLink } from './WhatWeDo.styles';

const WhatWeDo = () => (
    <Wrapper>
        <Content>
            <div className='headings'>
                <h1>Discover 200WEST</h1>
            </div>
            <div className='japanese'>
                <p>
                “私たちは日本に根付いたグローバルな人材の機関として日本のお客様に最高レベルのサービスを提供できるよう努めています。”
                </p>
            </div>
            <div className='words'>
                <p>Our clients always come first. We serve them through an investment professional community powered by partnership, integrity, and a shared purpose of advancing sustainable economic growth and financial opportunity.</p>
            </div>
            <MenuLink href="/Services" >
            Learn more about what we do
            </MenuLink>
        </Content>
    </Wrapper>
);

export default WhatWeDo;