import React from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Rounting
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Components
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Urfund from './components/Urfund';

// Styles
import { GlobalStyle } from './GlobalStyle';

const App = () => (

  <Router>
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/About' element={<AboutUs />} />
      <Route path='/Services' element={<Services />} />
      <Route path='/Urfund' element={<Urfund />} />
    </Routes>
    <Footer />
    <GlobalStyle />
  </Router>
);


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbON3hC5wKMlY9ap6v9pErjR_T-0T_Nao",
  authDomain: "w-66321.firebaseapp.com",
  projectId: "w-66321",
  storageBucket: "w-66321.appspot.com",
  messagingSenderId: "918948300546",
  appId: "1:918948300546:web:86990097f722debf07241b",
  measurementId: "G-2KX7BHNJ3W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export default App;
