import React from 'react';
// Styles
import { Wrapper, Content } from './OurServices.styles';

const Intro = () => (
    <Wrapper>
        <Content>
            <div className='heading'>
            <h1>What We Do</h1>
            </div>
            <div className='japanese'>
            <p>
            “多様化するお客様の金融ニーズに応えるべく業務の拡充を図ってまいりました。現在は、海外機関投資家の目線からのESGコンサルティング(海外評価期間社と海外投資家の評価基準やスコア改善の具体的なアクションプラン)、IRアドバイザリー(海外投資家とコミュニケーション、理念→戦略→作戦→戦術に基づいた中期計画、株主の情報収集と相場に配信の戦略)、元海外機関投資家出身の社外取締役候補者の紹介、非上場会社のバリュエーション評価、アクティビスト対策(空売り対策、アクティビストとコミュニケーションと訴訟)などを含む幅広い金融サービスを提供しています。”
            </p>
            </div>
            <div className='words'>
                <p>
                <strong>200WEST</strong> is a leading advisory that delivers a broad range of financial services across investor relations advisory, strategic consulting and litigation/representation to a large and diversified client base that includes corporations, financial institutions and individuals.
                </p>
            </div>
        
            <div className='divline'>
               <hr/>
            </div>
           
        </Content>
    </Wrapper>
);

export default Intro;