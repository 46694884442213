import React from 'react';

// Components
import HeroImage from './HeroImage';
import Intro from './Intro';
import image1 from '../images/nymain.jpg';

const AboutUs = () => (
    <>
        
        <HeroImage Title={'About Us'} Image={image1}/>
        <Intro />
       

    </>

);

export default AboutUs;
