import React from 'react';

// Styles
import {Wrapper, Content} from './HeroImage.styles';


const HeroImage = ({ Title, Image }) => (
    <Wrapper image={Image}>
        <Content>
                <h1>{Title}</h1>
        </Content>
    </Wrapper>
)

export default HeroImage;