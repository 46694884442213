import React from 'react';

// Components
import HeroCarousel from './HeroCarousel';
import WhatWeDo from './WhatWeDo';
import Contact from './Contact';

const Home = () => {
    
    return (
        <>
                <HeroCarousel
                />
            
            <WhatWeDo />
            <Contact />
            
        </>
    )

};


export default Home;