import styled from 'styled-components';

export const Wrapper = styled.div`
    background: linear-gradient(
        to bottom, 
        rgba(0,0,0,0) 41%, 
        rgba(0,0,0,0.65) 100%
        ), var(--darkGrey);
    
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
    animation: animateHeroImage 1s;

    @keyframes animateHeroIamge {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;    
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }

    .heroImg {
        max-height:600px;

        @media screen and (max-width: 768px) {
        max-height: 100%;
    }

    }
`;



/*
export const Content = styled.div`
    padding: 20px;
    margin: 0 auto;
    max-width: 0 auto;
`;

export const Text = styled.div`
    z-index: 100;
    max-width: 700px;
    position: absolute;
    bottom: 40px;
    margin-right: 20px;
    min-height: 100px;
    color: var(--white);

    h1 {
        font-size: var(--fontSuperBig);

        @media screen and(max-width: 720px) {
            font-size: var(--fontBig);
        }
    }

    p{
        font-size: var(--fontMed);

        @media screen and(max-width: 720px) {
            font-size: var(--fontSmall);
        }
    }

    @media screen and (max-width: 720px) {
        max-width: 100%;
    }
    `;
*/