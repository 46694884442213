import React from 'react';

// Components
import HeroImage from './HeroImage';
import OurServices from './OurServices';
import image2 from '../images/tokyotower.jpg'

const AboutUs = () => (
    <>
        
        <HeroImage Title={'Our Services'} Image={image2}/>
        <OurServices />
       

    </>

);

export default AboutUs;
