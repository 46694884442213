import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/200W_f8a61f.png';
import { Wrapper, Content, LogoImg, Hamburger, Menu, MenuLink } from './Header.styles';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
    <Wrapper>
        <Content>
            <div className='titles'>
            <Link to='/'>
                <LogoImg src={Logo} alt='logo' />
            </Link>
            {/* <div className='title1'>
            <h1></h1>
            <hr/>
            <p>ADVISORY & CONSULTING</p>
    </div> */}
        </div>
        
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
                <span />
                <span />
                <span />
            </Hamburger>
            <Menu isOpen={isOpen}>
                 
                <MenuLink href="/About">About Us</MenuLink>
                <MenuLink href="/Services">What We Do</MenuLink>
                <MenuLink href="/#contact-section">Contact Us</MenuLink>
                <MenuLink href="/Urfund">UrFund</MenuLink>
            </Menu>
        </Content>
    </Wrapper>
    )
};

export default Header;



