import React from 'react';
import { Wrapper, Content, Menu, MenuLink, LogoImg } from './Footer.styles';

const Footer = () => (

    <Wrapper>
        <Content>
            <Menu>
                <MenuLink>
                    <p>&#169; 2024 <a>200WEST</a> All Rights Reserved.</p>
                </MenuLink>
            </Menu>
        </Content>
    </Wrapper>

);

export default Footer;



