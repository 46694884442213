import React from 'react';
//styles
import { Wrapper, Content } from './HeroCarousel.styles';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import pic1 from './HeroImages/tokyo1.png';
import pic2 from './HeroImages/newyork2.jpeg';
import pic3 from './HeroImages/london3.jpeg';
import pic4 from './HeroImages/hongkong4.jpeg';
const HeroCarousel = () => {
    return (
        <Wrapper>
            <Content>
                <Carousel interval={5000} autoPlay={true} showThumbs={false} showIndicators={false} 
                showStatus={false} infiniteLoop={true}>
                    <div className='heroImg'>
                        <img src={pic1} />
                    </div>
                    <div className='heroImg'>
                        <img src={pic2} />
                    </div>
                    <div className='heroImg'>
                        <img src={pic3} />
                    </div>
                    <div className='heroImg'>
                        <img src={pic4} />
                        </div>
                </Carousel>
            </Content>
        </Wrapper>
    );
};

export default HeroCarousel;