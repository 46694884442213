import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: var(--maxWidth);
    margin: 0 auto;
    min-height: 400px;
    padding-top: 20px;
    height: 100%;

    @media screen and (max-width: 768px) {
        min-height:300px;
    }

    h1 {
        color: var(--medGrey);
        font-family: Garamond;
        font-size: 2.7rem;
        font-weight: lighter;

        @media screen and (max-width: 768px) {
            font-size: var(--fontBig);
            height: 50px;
            padding-top: 20px;
        }
    }

    p {
        font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;

    }


`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    place-content: space-between center;
    
    padding-left: 240px;
    padding-right:240px;
   

    @media screen and (max-width: 768px) {
         padding-left: 50px;
         padding-right: 50px;
         font-size: var(--fontMed)
        }

    .japanese {
        padding-bottom: 30px;
        text-align: center;

        p{
            color: black !important;
        }
      
    }    

    .words {
        font-size: 1.1rem;
        text-align: center;
        padding-bottom: 50px;
        
        @media screen and (max-width: 768px) {
         font-size: 0.8rem
        }
    }
    
    .divline {
        min-width:450px;
        padding-bottom: 30px;
        @media screen and (max-width: 767px) {
            min-width: 200px;
        }
    };;
    
`;

export const MenuLink = styled.a`
    font-size: 1.1rem;
    text-align: center;
    text-decoration: underline;
    color: #1E87FF;
    padding-bottom: 40px;

   
    transition: all 0.3s ease-in;
    cursor: pointer;
    
    &:hover {
        color: #eeba2b;
    }

    @media screen and (max-width: 768px) {
        padding-top: 15px;
        font-size: 0.8rem;
    }
`;