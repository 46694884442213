import React from 'react';
import logo from '/Users/rxrotary/Documents/200West/2024ver/website/src/images/UrFund_jp.png';
// Styles
import { Wrapper, Content, MenuLink } from './News.styles';

const WhatWeDo = () => (
    <Wrapper>
        <Content>
            <div className='headings'>
                <h1><strong>200WEST</strong> wins Audience Award at FINOPITCH 2024</h1>
            </div>
            <div className='words'>
                <p>
                <strong>200WEST </strong>(“UrFund”) won the Audience Award at FINOPITCH 2024. FINOPITCH 2024 was held in March 7th, 2024 at the heart of Tokyo, in Ginza(‘Kanze Noh Theater’), as a global fintech startup pitch contest.
                </p>
        
                <p>
                FINOPITCH started in 2021 under 4F, or “Future Frontier Fes by FINOLAB.” 4F has been hosted by FINOLAB since 2020 as an annual industry event with the aim to revitalize the financial industry.
                </p>
                <p>
                This year, the 23 short-listed fintech startups, 11 from Japan and 12 from overseas, made English presentations on stage to compete for awards with 4 judging criteria: 
                <br/>
                (a) impact on the financial industry, (b) innovative business model, (c) growth potential, and (d) global potential. The event was globally distributed online, and the participants voted for the best pitch during the event.
                </p>
                <p>
                The Audience Award was chosen from both onsite participants and online audience votes, while all the other awards were selected among the judges and sponsors.
                </p>
                <p>
                The following link shows the list of award winners at the event:
                </p>
                <p>
                <a href="https://4f-otmcbldg.tokyo/finopitch2024-award-winner/">FINOPITCH2024-Award-Winners</a>
                </p>
            </div>
            <div className='video'>
            <iframe width='100%' height='100%' src="https://www.youtube.com/embed/isR6u9NzgGc?si=ZpM36PnK03irB3LG" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='headings'>
                <img src={logo} alt="Logo" href="https://ur-fund.com/" />
            </div>
            <div className='japanese'>
            <p>
            “「UrFund」はファイナンシャルアドバイザーがより自分のお客様のために良い投資信託を探索・発見することができCustomize Solutionを提供できる投資信託学習プラットフォームです。
私たちのミッションは「データを通じて、すべての人に経済的自由を」です。”
            </p>
            </div>
            <div className='words1'>
                <p>
                UrFund is a B2B media platform for financial advisors to explore and find customized solution for their clients. Our mission is to bring financial freedom for everyone through data.
                </p>
                <p>
                UrFund official website: <a href="https://ur-fund.com/">https://ur-fund.com/</a>
                </p>
            </div>
            <div className='video'>
            <iframe width='100%' height='100%' src="https://www.youtube-nocookie.com/embed/A7liYv1aM8w?si=7sdMV7Leb8tomRoC" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </Content>
    </Wrapper>
);

export default WhatWeDo;