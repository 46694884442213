import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: var(--maxWidth);
    margin: 0 auto;
    min-height: 400px;
    padding-top: 20px;
    height: 100%;

    @media screen and (max-width: 768px) {
        min-height:170px;
    }

    h1 {
        color: var(--medGrey);
        font-family: Arial, sans-serif;
        font-size: 2.7rem;
        font-weight: lighter;

        @media screen and (max-width: 768px) {
            font-size: var(--fontBig);
        }
    }

    p {
        font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;

    }


`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    place-content: space-between;
    padding-left: 240px;
    padding-right:240px;
   

    @media screen and (max-width: 768px) {
         padding-left: 50px;
         padding-right: 50px;
         font-size: var(--fontMed)
        }

    .japanese {
        text-align: left;
        p{
            color: black !important;
        }
      
    }    
    img{
        width: 45vw;
    }}


    .words {
        font-size: 1.1rem;
        text-align: left;

        
        @media screen and (max-width: 768px) {
         font-size: 0.8rem
        }
    }

    .words1 {
        font-size: 1.1rem;
        text-align: left;
        padding-bottom: 20px;
        
        @media screen and (max-width: 768px) {
         font-size: 0.8rem
        }
    }
    
    .video {
        display: flex;
        flex-wrap: wrap;
        place-content: space-between;
        width: 100vw;
        height:45vh;
        padding-bottom: 50px;

        @media screen and (max-width: 768px) {
        width: 80vw;
        height:26vh;
       }
    };
    
`;

export const MenuLink = styled.a`
    font-size: 1.1rem;
    text-align: center;
    text-decoration: underline;
    color: #1E87FF;
    padding-bottom: 40px;

   
    transition: all 0.3s ease-in;
    cursor: pointer;
    
    &:hover {
        color: #ffe600;
    }

    @media screen and (max-width: 768px) {
        padding-top: 15px;
        font-size: 0.8rem;
    }
`;