import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import { Wrapper, Content } from './Intro.styles';

const Intro = () => (
    <Wrapper>
        <Content>
            <div className='japanese'>
            <p>
            “海外機関投資家出身のファンドマネジャー、アナリストから国際弁護士、海外評価社のESGコンサルタントなど各分野で活躍しているトップ専門家の支援。”
            </p>
            </div>
            <div className='words'>
            <p>
            We’re all about finding the next big thing. Those hewing to the benchmarks, which are backwards looking, are not about the future. They are about what has worked. We’re all about what is going to work. 
            </p>
            </div>
            <div className='divline'>
               <hr/>
            </div>
            <div className='headings'>
                <h1>Our Purpose and Values</h1>
            </div>
            <div className='japanese'>
            <p>
            “日本を世界金融先進国に。東京を世界金融中心地に。”
            </p>
            </div>
            <div className='words'>
            <p>
            Our mission is to advance sustainable economic growth and financial opportunity for Japanese corporates. Drawing upon vast experience working with the world’s leading businesses, entrepreneurs, and institutions, we mobilize our people and resources to advance the success of our clients, broaden individual prosperity and accelerate economic progress for all.
            </p>
            </div>
            <div className='divline'>
               <hr/>
            </div>
            <div className='headings'>
                <h1>会社概要 : Company Info</h1>
            </div>
            <div className='japanese'>
            <p>
            〒106-0032 東京都港区六本木5-9-20 六本木イグノポール7F
            <br/>
            都営大江戸線／東京メトロ日比谷線　六本木駅
            <br/>
            3番出口より徒歩3分
            </p>
            </div>
            <div className='words'>
            <p>
            Ropponngi Ignoppor 7F, 5-9-20, Roppongi, Minato-ku, Tokyo 106-0032
            <br/>
            Toei Ōedo Line / Tokyo Metro Hibiya Line Roppongi Sta.
            <br/>
            3 min walk from Exit 3
            </p>
            </div>
            <div className='divline'>
               <hr/>
            </div>
        
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1620.8108110566611!2d139.73107922750893!3d35.66169295299911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b6ab61af7a5%3A0xce24f07b6f5e4be5!2z5YWt5pys5pyo44Kk44Kw44OO44Od44O844Or!5e0!3m2!1sen!2skr!4v1710323748754!5m2!1sen!2skr" 
            width="600" height="450" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        
            <div className='divline'>
                <br/>
               <hr/>
            </div>
           


            
        </Content>
    </Wrapper>
);

export default Intro;