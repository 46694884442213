import styled from 'styled-components';
// Images
export const Wrapper = styled.div`
    background: linear-gradient(
        to bottom, 
        rgba(0,0,0,0) 41%, 
        rgba(0,0,0,0.65) 100%
        ),url(${({ image }) => image}), var(--darkGrey);
    background-position: center;
    background-repeat: no-repeat;
 
    background-size: cover;
    
    @media screen and (max-width: 768px) {
        width: 100%;
        max-height: 300px;
        background-size: cover;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;

    @media screen and (max-width: 768px) {
        min-height: 170px;
    }
    ;
    
    h1 {
        font-family: Garamond;
        font-size: 2.7rem;
        padding-left: 15px;
        font-weight: lighter;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            padding-left: 5px;
    }}

`;

